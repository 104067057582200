import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from 'react-helmet';
import React from 'react';

const SEO = ({en}) => {
	const data = useStaticQuery(graphql`
		query seoQuery {
			allSite {
				nodes {
					siteMetadata {
						title
						enTitle
						description
						enDescription
						siteUrl
						keywords
						image
					}
				}
			}
		}
	`);

	const { title, description, siteUrl, keywords, image, enTitle, enDescription} =
		data.allSite.nodes[0].siteMetadata;

	return (
		<Helmet>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1"
			></meta>
			<title>{title}</title>
			<link rel="canonical" href={siteUrl} />
			<meta charSet="utf-8" />
			<meta name="keywords" content={keywords} />
			<meta name="description" content={en ? enDescription : description} />
			<meta name="language" content="en" />
			<meta name="robots" content="index,follow" />
			<meta name="url" content={siteUrl} />
			<meta name="identifier-URL" content={siteUrl} />
			<meta name="og:title" content={en ? enTitle : title} />
			<meta name="og:type" content="website" />
			<meta name="og:url" content={siteUrl} />
			<meta name="og:image" content={image} />
			<meta name="og:description" content={en ? enDescription : description}  />
		</Helmet>
	);
};

export default SEO;
